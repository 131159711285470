@import "style/app_vars";

.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  padding: .5rem 1rem;
  //background: $app-secondary-subtitle;
  //margin: 1rem 0;
  border-radius: 4px;
}

.crumb {
  color: rgba(139, 147, 155, .8);
}

.active {
  cursor: default;
  color: $app-breadcrumbs-active;
  text-decoration: none;
}

.main__item {
  text-decoration: none;
  text-wrap: nowrap;
  margin: 0;
}

.main__item:hover {
  text-decoration: underline;
}

