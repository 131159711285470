@import "../../style/app_vars";

.filter_button_count {
  min-width: 25px;
  font-size: .7rem;
  color: rgb(23, 70, 153);
  background-color: white;
  font-weight: bold;
  padding: 0 .25rem;
  border-radius: 50%;

}

.items__wrapper {
  width: 350px;
  min-width: 350px;
  height: 94vh;
  overflow: auto;
  background-color: white;
  direction: rtl;

  @media screen and (max-width: 1200px) {
    width: 100%;
    min-height: 94vh;
    min-width: unset;
    overflow-y: unset;
    max-height: unset;
  }
}

.items__container {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  direction: ltr;
  height: 100%;
}

.task__item {
  direction: ltr;
  padding: 0.25rem .5rem;
  background-color: white;
  color: #2f5379;
  text-decoration: none;
  animation: cardFade .3s forwards;

  &.active {
    background-color: $app-active-organization;
  }
}

.card__wrapper {
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  overflow: auto;
}

.task__card {
  width: 100%;
  animation: cardFade .3s forwards;
}

.add__task_modal__wrapper {
  width: 75%;

  @media screen and (max-width: 1200px) {
    width: 95%;
  }
}

.table_row {
  height: 32.8px;
}

@keyframes cardFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



