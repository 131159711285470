@import "../../../style/app_vars";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: .5rem 1rem;
  font-size: 1rem;
  position: relative;

  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  }
}

.header__button {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  border-radius: .25rem;
  border: none;
}

.header__search {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
}

.header__close {
  padding: .5rem;
}