.transfer__item {
  display: flex;
  align-items: center;
  padding: .25rem .5rem;
}

.transfer__item:nth-child(odd) {
  background-color: rgba(23, 70, 153, .1);
}

.transfer__item:hover {
  background-color: rgba(23, 70, 153, .9);
  color: white;
}

.transfer__item__block:not(:first-child) > h6 {
  margin-top: .5rem;
}

