.app__button {
  &_primary:hover:not(:disabled) {
    background-color: #143c82 !important;
    color: white !important;
  }

  &_outline-primary:hover:not(:disabled) {
    background-color: #174699 !important;
    color: white !important;
  }

  &_secondary:hover:not(:disabled) {
    background-color: #565e64 !important;
    color: white !important;
  }

  &_outline-secondary:hover:not(:disabled) {
    background-color: #6c757d !important;
    color: white !important;
  }

  &_success:hover:not(:disabled) {
    background-color: #146c43 !important;
    color: white !important;
  }

  &_outline-success:hover:not(:disabled) {
    background-color: #198754 !important;
    color: white !important;
  }

  &_danger:hover:not(:disabled) {
    background-color: #b02a37 !important;
    color: white !important;
  }

  &_outline-danger:hover:not(:disabled) {
    background-color: #dc3545 !important;
    color: white !important;
  }

  &_dark:hover:not(:disabled) {
    background-color: #373b3e !important;
    color: white !important;
  }

  &_outline-dark:hover:not(:disabled) {
    background-color: #212529 !important;
    color: white !important;
  }

  &_warning:hover:not(:disabled) {
    background-color: #ffca2c !important;
    color: #212529 !important;
  }

  &_outline-warning:hover:not(:disabled) {
    background-color: #ffca2c !important;
    color: #212529 !important;
  }
}

