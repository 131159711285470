.fancybox__content {
  width: 90% !important;

  @media screen and (max-width: 1200px){
    width: 98% !important;
  }
}

.fancybox__slide {
  padding-top: 20px !important;
}

