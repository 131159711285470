.att__table {
  position: relative;
  user-select: none;
  background-color: white;
}

.att__table-header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  background: white;
  text-align: center;
  font-weight: bold;
  line-height: 2;
  z-index: 1;
}

.att__table-employee {
  width: 180px;
  display: flex;
  align-items: center;
  padding: 0 .5rem;

  &.header {
    justify-content: center;
  }
}

.att__table-post {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .5rem;
  overflow: hidden;

  &.post-title {
    position: absolute;
    top: 50%;
    left: 0;
    white-space: nowrap;
    transform: translate(0, -50%);
  }
}

.att__table-text_cell {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .5rem;
}

.att__table-header-days {
  text-align: center;
  line-height: 1.25;
  text-transform: capitalize;
}

.att__table-department {
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
  line-height: 2;
}

.att__table-index {
  display: flex;
  align-items: center;
  justify-content: center;

  &.coefficient:hover {
    background-color: rgba(125, 125, 125, 0.25);
  }
}

.att__table-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: .25rem;
  overflow: hidden;

  &:hover {
    background-color: rgba(118, 175, 198, 0.9) !important;
  }
}

.att__table-mask {
  position: absolute;
  background-color: rgba(0, 123, 255, 0.3);
  pointer-events: none;
}

.att__table-menu__wrapper {
  display: flex;
  align-items: start;
  gap: .25rem;
  padding: .25rem;
  border-radius: .25rem;
  background-color: rgba(125, 125, 125, 0.5);
  z-index: 2;
}

.att__table-mobile__menu_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 20;
  padding: 1rem;
  overflow-Y: auto;
}

.att__table-menu {
  background-color: white;
  border-radius: .25rem;
  padding: .25rem 0;
  cursor: pointer;
  z-index: 1;


  @media screen and (max-width: 1200px) {
    padding: 0 !important;
    width: 100% !important;
    box-shadow: none;
  }

}

.att__table-menu-item {
  display: flex;
  gap: .25rem;
  padding: 0 .25rem;

  &:not(:last-child) {
    margin-bottom: .25rem;
  }

  &:hover {
    background-color: rgba(23, 70, 153, .3);
  }

  @media screen and (max-width: 1200px) {
    &:hover {
      background-color: transparent;
    }

  }
}

.att__table-menu-icon {
  width: 35px;
  padding: 0 .25rem;
  text-align: center;
  font-weight: bold;
}

.att__absence_icon {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 8px solid red;
  position: absolute;
  top: 0;
  right: 0;
}
