.calendar {
  position: relative;
  width: 100%;

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    z-index: 1;
    background-color: white;
    padding: 1rem;
  }
}

.calendar__menu {
  width: 100%;
  max-width: 320px;
  padding: .5rem;
  border-radius: .25rem;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
}

.calendar__input {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
  border-radius: .25rem 0 0 .25rem;
  padding-right: 2rem;
}

.timepicker_reset_button {
  position: absolute;
  top: 52%;
  right: 3rem;
  transform: translate(0, -50%);

  &:hover {
    opacity: 0.5;
  }
}

.calendar__input_group {
  border-radius: 0 .25rem .25rem 0;

  &.mobile {
    background-color: #198754;
    border-color: #198754;
  }
}

.calendar__title {
  text-transform: capitalize;
  min-width: 100px;
}

.buttons__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
}

.cell__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: .25rem;

}

.picker__cell {
  width: 25%;
  flex-grow: 1;
  text-align: center;
}

.timepicker__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  user-select: none;
  font-size: 1rem;

  &:first-child .timepicker__item_middle {
    border-radius: .5rem 0 0 .5rem;
  }

  &:last-child .timepicker__item_middle {
    border-radius: 0 .5rem .5rem 0;
  }
}

.timepicker__item_outer {
  opacity: 0.7;
}

.timepicker__item_middle {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(226, 227, 229);
}

.timepicker__item_middle_title {
  position: absolute;
  top: 50%;
  left: 120%;
  transform: translate(0, -50%);
}



