.app__collapse {
  //width: 100%;
  overflow: visible;

  &.show {
    transition: height 0.3s ease-in-out, opacity 0.3s .2s ease-in-out, visibility 0.3s ease-in-out;
  }

  &.hide {
    transition: height 0.3s .2s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s .2s ease-in-out;
  }

}