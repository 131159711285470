.dots {
  display: inline-flex;
  font-weight: bold;
}

.dot {
  opacity: 0;
  margin-right: .15rem;
  animation: blink 1.5s forwards infinite;
}

.dot:nth-child(1) {
  animation-delay: .1s;
}

.dot:nth-child(2) {
  animation-delay: .4s;
}

.dot:nth-child(3) {
  animation-delay: .6s;
}

@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}